import React, { useEffect, useState } from "react"
import { css } from "@emotion/core"

import Layout from "../components/layout/"
import Head from "../components/head"
import PageHeading from "../components/pageHeading"
import Form from "../components/Form"

import testimonials from "../cms/testimonials"

export default () => {
  const [name, setName] = useState("")
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")

  const toEmail = testimonials.email

  const [mailTo, setMailTo] = useState("mailto:" + toEmail)

  const handleName = e => setName(e.target.value)
  const handleSubject = e => setSubject(e.target.value)
  const handleMessage = e => setMessage(e.target.value)

  useEffect(() => {
    setMailTo("mailto:" + toEmail + "?subject=" + subject + "&body=" + message)
  }, [name, subject, message, toEmail])

  return (
    <Layout>
      <Head title="Contact" />

      <PageHeading text="Contact" centered />

      <Form
        style={css`
          margin: 0 auto;
        `}
      >
        <label htmlFor="name">Name</label>
        <input id="name" type="text" required 
          value={name}
          onChange={handleName}
        />

        <label htmlFor="email">Subject</label>
        <input id="subject" type="text" required 
          value={subject}
          onChange={handleSubject}
        />

        <label htmlFor="message">Message</label>
        <textarea id="message" required
          value={message}
          onChange={handleMessage}
        ></textarea>

        <a href={mailTo}>Send ⟶</a>
      </Form>
    </Layout>
  )
}