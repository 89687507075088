import React from "react"
import { css } from "@emotion/core"

export default props => {
  return (
    <form
        css={css`
          max-width: 40rem;

          * {
            box-sizing: border-box;
          }

          label {
            color: var(--text-light-gray);
            margin-bottom: .3rem;
            display: block;
          }

          input, textarea, a {
            appearance: none;
            width: 100%;
            background: transparent;
            border: none;
            border: 2px solid lightgray;
            margin-bottom: 1rem;
            border-radius: .5rem;
            padding: .7rem;
          }

          textarea {
            resize: vertical;
            min-height: 15rem;
          }

          input[type="submit"], a {
            background-color: var(--background-dark-secondary);
            border: none;
            color: white;
            font-weight: 500;
            display: block;
            text-align: center;
            text-decoration: none;

            transition: opacity .15s;

            :hover {
              opacity: .9;
            }
          }
          
          ${props.style}
        `}
      >
        {props.children}
      </form>
  )
}